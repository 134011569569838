'use client';

import React, { useMemo, forwardRef } from 'react';
import classNames from 'classnames';
import { Game } from '@/entities/game';
import dynamic from 'next/dynamic';

const GameCardContent = dynamic<{ game: Game; size?: 'default' | 'compact' }>(
  () => import('./game-card-content').then((module) => module.GameCardContent),
  { ssr: false }
);

type Props = {
  data: Game;
  className?: string;
  demoAvailable?: boolean;
  dataTestId?: string;
  autoSize?: boolean;
  device?: 'desktop' | 'mobile';
  size?: 'default' | 'compact';
  titleClassName?: string;
  providerClassName?: string;
};

const ICON_FALLBACK = '/games/fallback.png';

const CARD_SIZES = {
  default: {
    container: 'md:h-[288px] md:w-[220px] h-[182px] w-[140px]',
    image: 'md:h-[220px] md:w-[220px] h-[140px] w-[140px]'
  },
  compact: {
    container: 'md:h-[182px] md:w-[140px]',
    image: 'md:h-[140px] md:w-[140px]'
  }
};

export const GameCard = forwardRef<HTMLAnchorElement, Props>(
  (
    {
      dataTestId,
      data,
      className,
      autoSize,
      device = 'desktop',
      size = 'default',
      titleClassName,
      providerClassName
    },
    ref
  ) => {
    const { name, icon, providerIcon, providerIconAlt } = data;

    const gameIcon = icon?.url || providerIcon || null;

    const gameIconAlt = icon?.alt || providerIconAlt || null;

    const sizeClasses = CARD_SIZES[size];

    return (
      <div
        className={classNames(
          'group',
          autoSize ? 'h-auto w-auto' : '',
          sizeClasses.container,
          className
        )}
      >
        <div
          data-testid={dataTestId || 'gameCard'}
          className={classNames(
            'relative block aspect-square rounded-2',
            autoSize ? 'h-auto w-auto' : '',
            sizeClasses.image
          )}
        >
          <GameCardContent game={data} size={size} />
          <img
            src={gameIcon || ICON_FALLBACK}
            alt={gameIconAlt ?? name}
            onError={({ currentTarget }) => {
              // TODO: make an ImageFallback component with better error handling
              currentTarget.onerror = null;
              currentTarget.src = ICON_FALLBACK;
            }}
            className="bg-game-fallback h-full w-full rounded-2 object-cover object-center"
          />
        </div>
        <div className={classNames('mt-1/2', size === 'compact' ? 'md:mt-1/2' : 'md:mt-1')}>
          <div
            className={classNames(
              'game-card-provider mb-1/2 truncate text-text-info',
              device === 'desktop' ? 'group-hover:text-text-brand' : 'group-active:text-text-brand',
              providerClassName,
              size === 'compact' ? 'typography-mobile-info' : 'typography-info '
            )}
          >
            {data.provider}
          </div>
          <div
            className={classNames(
              'game-card-title typography-mobile-button-small-semibold line-clamp-1 text-text-primary',
              device === 'desktop' ? 'group-hover:text-text-brand' : 'group-active:text-text-brand',
              titleClassName,
              size === 'compact'
                ? 'md:typography-mobile-button-small-semibold md:line-clamp-1'
                : 'md:typography-title-bold md:line-clamp-2'
            )}
          >
            {data.name}
          </div>
        </div>
      </div>
    );
  }
);

GameCard.displayName = 'GameCard';
