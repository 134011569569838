import Link from 'next/link';

import { Block, getDataTestId } from '@/shared/lib/get-data-testid';
import { NewButton as Button } from '@/shared/ui';
import classNames from 'classnames';

type Props = {
  href?: string;
  children: React.ReactNode;
  navigation?: React.ReactNode;
  className?: string;
  buttonText?: string | null;
  onButtonClick?: () => void;
  argsForDataTestId?: {
    nameForTargetBlock: Block;
    modification: string;
  };
  buttonClassName?: string;
  titleClassName?: string;
};

const Heading: React.FC<Props> = ({
  children,
  href,
  navigation,
  buttonText,
  onButtonClick,
  argsForDataTestId,
  buttonClassName,
  titleClassName
}) => {
  const buttonTestId = argsForDataTestId
    ? getDataTestId({
        block: argsForDataTestId.nameForTargetBlock,
        nameForTargetElement: 'button',
        modification: argsForDataTestId.modification
      })
    : '';

  const linkTestId = argsForDataTestId
    ? getDataTestId({
        block: argsForDataTestId.nameForTargetBlock,
        nameForTargetElement: 'link',
        modification: argsForDataTestId.modification
      })
    : '';

  const headingTestId = argsForDataTestId
    ? getDataTestId({
        block: argsForDataTestId.nameForTargetBlock,
        nameForTargetElement: 'heading',
        modification: argsForDataTestId.modification
      })
    : '';

  return (
    <h2
      className={classNames(
        'typography-mobile-system-headline flex content-center items-center justify-between pb-2 md:typography-headline-1',
        titleClassName
      )}
      data-testid={headingTestId}
    >
      {href != null ? (
        <Link data-testid={linkTestId} href={href} className="flex items-center">
          {children}
        </Link>
      ) : (
        children
      )}
      <div className="ms-2 flex justify-between gap-1 align-middle md:ms-3">
        {(href != null || onButtonClick) && (
          <Button
            href={href}
            dataTestId={buttonTestId}
            className={classNames('w-[110px] md:w-[181px]', buttonClassName)}
            variant="secondary"
            onClick={onButtonClick}
          >
            {buttonText}
          </Button>
        )}
        {navigation}
      </div>
    </h2>
  );
};

export default Heading;
