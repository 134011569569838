'use client';

import Heading from '@/features/home/ui/Heading';
import { useI18n } from '@/shared/locales/client';
import { Button } from '@/shared/ui';

type Props = {
  className?: string;
  children: React.ReactNode;
  title: string;
  href: string;
  buttonText?: string;
};

export const TilesLayout: React.FC<Props> = ({ title, className, href, children, buttonText }) => {
  const t = useI18n();

  return (
    <div className={className}>
      <Heading>
        {title}{' '}
        <Button
          variant="border"
          className="h-[30px] min-w-[96px] rounded-sm !px-0 text-sm/28"
          href={href}
        >
          {buttonText || t('main.showAll')}
        </Button>
      </Heading>
      {children}
    </div>
  );
};
