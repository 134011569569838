export const getProviderNameUrlNormalize = (providerName: string) => {
  if (typeof providerName !== 'string' || !providerName.trim()) {
    return null;
  }
  return providerName.trim().replaceAll(' ', '-');
};

export const getProviderNameFromURL = (url: string) => {
  if (typeof url !== 'string' || !url.trim()) {
    return null;
  }
  return url.replaceAll('-', ' ').trim();
};
