import { gql } from '@apollo/client';

import { Game } from './types';
import { baseCategoryQglDescriptor, Category } from '../category';

const baseGameDescriptor = `
id
gameId
name
slug
demoAvailable
provider
icon {
  url
  alt
}
providerIcon
providerIconAlt
seo {
  metaTitle
  metaDescription
  ogImage {
    url
    alt
  }
  seoText
  titleH1
}
  aggregator
  externalId
`;

export const gamesQuery = gql`
  query Games($input: GamesInput) {
    games(input: $input) {
      success
      items {
        ${baseGameDescriptor}
      }
      total
      limit
      page
      hasNextPage
      hasPrevPage
      error {
        message
        stack
      }
    }
  }
`;
export const getGameByIdQuery = gql`
  query GetGameById($gameId: Int!) {
    getGameByGameId(gameId: $gameId) {
      success
      message
      error {
        message
        stack
      }
      game {
        slug
        name
        gameId
        provider
        providerIcon
        providerIconAlt
        externalId
        icon {
          url
          alt
        }
      }
    }
  }
`;

export const gamesByCategoryQuery = gql`
  query GamesByCategory($input: GamesByCategoryInput) {
    gamesByCategory(input: $input) {
      success
      items {
        ${baseGameDescriptor}
      }
      category {
        ${baseCategoryQglDescriptor}
        showRestGames
        seo {
          metaTitle
          metaDescription
          ogImage {
            url
            alt
          }
          seoText
          titleH1
        }
      }
      total
      limit
      page
      hasNextPage
      hasPrevPage
      error {
        message
        stack
      }
    }
  }
`;

export const getGameLaunchUrlQuery = gql`
  mutation getGameLaunch($input: GameLaunchInput!) {
    getGameLaunch(input: $input) {
      success
      message
      gameUrl
    }
  }
`;

type Success = {
  success: true;
  items: Game[];
  total: number;
  limit: number;
  page: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
};

type Error = {
  success: false;
  error: {
    message: string;
    stack?: string;
  };
};

export type GamesResponse = {
  games: Success | Error;
};

export type GamesByCategoryResponse = {
  gamesByCategory: (Success & { category: Category }) | Error;
};

export type GameLaunchResponse = {
  getGameLaunch: (Success & { message: string; gameUrl: string }) | Error;
};

export type Device = 'desktop' | 'mobile';
