import React from 'react';
import classNames from 'classnames';
import { ArrowNavButton } from '@/shared/ui/arrow-nav-button';

type PaneArrowProps = {
  onClick?: (e: React.SyntheticEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  direction: 'right' | 'left';
  className?: string;
  dataTestid?: string;
  disabled?: boolean;
};

export const PaneArrow: React.FC<PaneArrowProps> = ({
  onClick,
  direction,
  className,
  dataTestid,
  disabled
}) => (
  <ArrowNavButton
    disabled={disabled}
    onClick={onClick}
    dataTestId={dataTestid}
    className={classNames(
      {
        '-scale-x-100': direction === 'right'
      },
      className
    )}
  />
);
