import { useQuery } from '@apollo/client';
import { categorySeoQuery } from '../api';
import { CategorySeoResponse, Device } from '../types';

export const useCategorySeo = (input: { slug?: string; devices: Device[] }) => {
  const { loading, error, data } = useQuery<CategorySeoResponse>(categorySeoQuery, {
    variables: {
      input
    },
    skip: !input.slug
  });

  const gamesByCategoryResult = data?.gamesByCategory;
  const success = gamesByCategoryResult?.success;

  return {
    error,
    loading,
    success,
    categorySeo: success && gamesByCategoryResult.total ? gamesByCategoryResult.category.seo : null
  };
};
