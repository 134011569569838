import { Game } from '../types';
import { TilesLayout } from './tiles-layout';
import { GameCard } from './game-card';
import React from 'react';

type Props = {
  games: Game[];
  className?: string;
  title: string;
  href: string;
  buttonText?: string;
};

export const GamesTiles: React.FC<Props> = ({ games, className, title, href, buttonText }) => {
  if (!games.length) return null;

  return (
    <TilesLayout className={className} title={title} href={href} buttonText={buttonText}>
      <div className="mb-2 grid w-full grid-cols-3 gap-1">
        {games.slice(0, 6).map((data, index) => (
          <GameCard data={data} key={index} />
        ))}
      </div>
    </TilesLayout>
  );
};
