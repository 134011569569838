'use client';

import React from 'react';
import classNames from 'classnames';
import { usePathname, useRouter } from 'next/navigation';
import { RoutesConfig } from '@/shared/routes/config';
import { getProviderNameUrlNormalize } from '@/shared/lib/provider-name-url';
import { safeLocalStorage } from '@/shared/lib/safe-local-storage';
import { localStorageConsts } from '@/shared/const';

type Props = {
  name: string;
  image?: string;
  autoSize?: boolean;
  imageClassName?: string;
  className?: string;
  dataTestId?: string;
};

const ProviderCard = React.forwardRef<HTMLDivElement, Props>(
  (
    { className: externalClassName, name, image, dataTestId, autoSize = false, imageClassName },
    ref
  ) => {
    const { push } = useRouter();
    const pathname = usePathname();
    const handleRedirect = (providerName: string) => {
      if (!pathname.includes(RoutesConfig.casinoGamesByProvider)) {
        safeLocalStorage.setItem(localStorageConsts.providerTriggerURL, pathname);
      }
      push(`${RoutesConfig.casinoGamesByProvider}/${getProviderNameUrlNormalize(providerName)}`);
    };

    return (
      <div
        onClick={() => handleRedirect(name)}
        data-testid={dataTestId || ''}
        ref={ref}
        className={classNames(
          'relative rounded-2 bg-background-card px-2 py-[12px] transition-colors duration-200 ease-in-out md:py-3 md:pb-0 md:hover:bg-background-tag lg:py-3',
          'flex items-center justify-center',
          'cursor-pointer',
          autoSize
            ? 'h-auto w-full'
            : 'h-[60px] w-[140px] min-w-[140px] md:h-[120px] md:w-[193px] md:min-w-[193px] lg:h-[120px] lg:w-[220px] lg:min-w-[220px]',
          externalClassName
        )}
      >
        {image && (
          <img
            alt={name}
            src={image}
            className={classNames(
              'h-[40px] w-full object-contain object-center md:h-[72px] lg:h-[72px] lg:w-[188px]',
              imageClassName
            )}
          />
        )}
      </div>
    );
  }
);

ProviderCard.displayName = 'ProviderCard';

export default ProviderCard;
