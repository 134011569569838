'use client';

import React, { ReactNode, useRef, useState } from 'react';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';

import { Block, getDataTestId } from '@/shared/lib/get-data-testid';
import classNames from 'classnames';
import { Game } from '../types';
import { Device } from '../api';
import { GameCard } from './game-card';
import Heading from '@/features/home/ui/Heading';
import { useI18n } from '@/shared/locales';
import { PaneArrow } from './pane-arrow';
import { Navigation, Grid } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/grid';
import './styles.css';
import { CaretLeftIcon } from '@/shared/ui';

const MAX_ITEMS_LENGTH = 20;

type Props = {
  id: string;
  title: string | ReactNode;
  games: Game[];
  href?: string;
  total?: number | null;
  doubleRow?: boolean;
  device?: Device;
  className?: string;
  cardSize?: 'default' | 'compact';
  cardTitleClassName?: string;
  cardProviderClassName?: string;
  argsForDataTestId?: {
    nameForTargetBlock: Block;
    modification: string;
  };
  titleClassName?: string;
  onAllClick?: () => void;
};

export const GamesPane: React.FC<Props> = ({
  id,
  title,
  className,
  games,
  total,
  href,
  argsForDataTestId,
  doubleRow,
  device = 'desktop',
  cardSize = 'default',
  cardTitleClassName,
  cardProviderClassName,
  titleClassName,
  onAllClick
}) => {
  const t = useI18n();
  const swiperRef = useRef<SwiperClass>(undefined);

  if (!games || games.length === 0) return null;

  return (
    <div
      data-testid={argsForDataTestId ? getDataTestId(argsForDataTestId) : ''}
      className={classNames('game-pane', className)}
    >
      <Heading
        titleClassName={titleClassName}
        argsForDataTestId={argsForDataTestId}
        href={href}
        buttonText={`${t('main.all')} ${total || ''}`}
        onButtonClick={onAllClick}
        navigation={
          <>
            <PaneArrow
              direction="right"
              className={classNames('hidden md:!block', `banner-prev-${id}`)}
            />
            <PaneArrow
              direction="left"
              className={classNames('hidden md:!block', `banner-next-${id}`)}
            />
          </>
        }
      >
        <span className="text-text-primary">{title}</span>
      </Heading>
      <div className="games-pane relative select-none">
        <Swiper
          grid={
            device === 'mobile' && doubleRow && games.length > 2
              ? { rows: 2, fill: 'column' }
              : undefined
          }
          loopAddBlankSlides={doubleRow}
          watchOverflow
          modules={[Grid, Navigation]}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          spaceBetween={8}
          slidesPerView="auto"
          breakpoints={{
            982: {
              spaceBetween: 16
            }
          }}
          navigation={{
            nextEl: `.banner-next-${id}`,
            prevEl: `.banner-prev-${id}`
          }}
        >
          {games.slice(0, MAX_ITEMS_LENGTH).map((game, index) => (
            <SwiperSlide key={index} className="!w-auto select-none">
              <GameCard
                data={game}
                dataTestId={getDataTestId({
                  block: argsForDataTestId?.nameForTargetBlock,
                  nameForTargetElement: 'gameCard',
                  modification: game.slug
                })}
                device={device}
                size={cardSize}
                titleClassName={cardTitleClassName}
                providerClassName={cardProviderClassName}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default GamesPane;
