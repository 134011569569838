import { Device, GamesByCategoryResponse, gamesByCategoryQuery } from '@/entities/game';
import { useQuery } from '@apollo/client';

type Params = {
  slug?: string;
  device: Device;
  excludeId?: string[];
};

export const useGamesByCategory = ({ slug, device, excludeId }: Params) => {
  const { loading, error, data } = useQuery<GamesByCategoryResponse>(gamesByCategoryQuery, {
    variables: {
      input: {
        slug,
        devices: [device],
        excludeId
      }
    },
    skip: !slug
  });

  const gamesByCategoryResult = data?.gamesByCategory;
  const success = gamesByCategoryResult?.success;

  return {
    error,
    loading,
    success,
    games: success ? gamesByCategoryResult.items : null,
    category: success ? gamesByCategoryResult.category : null,
    total: success ? gamesByCategoryResult.total : null
  };
};
