import { gql } from '@apollo/client';

export const baseCategoryQglDescriptor = `
slug
name
title
`;

export const categoriesQuery = gql`
  query Categories($input: CategoriesInput) {
    categories(input: $input) {
      success
      categories {
        ${baseCategoryQglDescriptor}
        seo {
          metaTitle
          metaDescription
          ogImage {
            url
            alt
          }
          seoText
          titleH1
        }
      }
      error {
        message
        stack
      }
    }
  }
`;

export const categorySeoQuery = gql`
  query GamesByCategory($input: GamesByCategoryInput) {
    gamesByCategory(input: $input) {
      success
      category {
        slug
        seo {
          metaTitle
          metaDescription
          ogImage {
            url
            alt
          }
          seoText
          titleH1
        }
      }
      total
      error {
        message
        stack
      }
    }
  }
`;
