import React from 'react';
import { Skeleton } from '@/shared/ui';
import { repeatComponent } from '@/shared/lib/repeat-component';
import classNames from 'classnames';

export const GamesPaneSkeleton = ({ className }: { className?: string }) => (
  <div className={classNames('mb-4t', className)}>
    <Skeleton className="mb-2t h-4t w-18t rounded-2xl" />
    <div className="flex flex-row gap-2t">
      {repeatComponent(<Skeleton className="h-18t w-18t flex-none rounded-md" />, 8)}
    </div>
  </div>
);
