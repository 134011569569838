import { Skeleton } from '@/shared/ui';
import { repeatComponent } from '@/shared/lib/repeat-component';

export const GamesTilesSkeleton: React.FC = () => (
  <div className="mb-4">
    <Skeleton className="mb-1 h-3t w-10t rounded-2xl" />
    <div className="grid w-full grid-cols-3 gap-1">
      {repeatComponent(<Skeleton className="aspect-square w-full" />, 6)}
    </div>
  </div>
);
